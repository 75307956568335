<template>
	<main class="page-container" id="join-intro">
		<section class="mbrp-section">
			<div v-if="isFindId" class="container">
				<header class="mbrp-header">
					<h2 class="title is-large">아이디 찾기</h2>
				</header>
				<div class="mbrp-body">
					<ul class="join-intro">
						<li>
							<div class="join-intro-circle">
								<span class="icon-wrap"><i class="icon-join-integ"></i></span>
								<span class="join-intro-text">
									<strong>통합기업회원</strong>
									<em>중소기업, 소상공인</em>
								</span>
								<!-- <router-link to="https://fanfandaero.kr/" class="button-default is-primary is-large is-rounded">아이디 찾기</router-link> -->
                <a @click="clickMoveFANFAN('id')" class="button-default is-primary is-large is-rounded">아이디 찾기</a>
							</div>
						</li>
						<li>
							<div class="join-intro-circle">
								<span class="icon-wrap"><i class="icon-join-general"></i></span>
								<span class="join-intro-text">
									<strong>일반회원</strong>
									<em>예비창업자 등 일반사용자</em>
								</span>
								<router-link to="/findInfo/id" class="button-default is-primary is-large is-rounded">아이디 찾기</router-link>
							</div>
						</li>
					</ul>
				</div>
			</div>
      <div v-else class="container">
				<header class="mbrp-header">
					<h2 class="title is-large">비밀번호 찾기</h2>
				</header>
				<div class="mbrp-body">
					<ul class="join-intro">
						<li>
							<div class="join-intro-circle">
								<span class="icon-wrap"><i class="icon-join-integ"></i></span>
								<span class="join-intro-text">
									<strong>통합기업회원</strong>
									<em>중소기업, 소상공인</em>
								</span>
								<!-- <router-link to="https://fanfandaero.kr/" class="button-default is-primary is-large is-rounded">비밀번호 찾기</router-link> -->
                <a @click="clickMoveFANFAN('pw')" class="button-default is-primary is-large is-rounded" >비밀번호 찾기</a>
							</div>
						</li>
						<li>
							<div class="join-intro-circle">
								<span class="icon-wrap"><i class="icon-join-general"></i></span>
								<span class="join-intro-text">
									<strong>일반회원</strong>
									<em>예비창업자 등 일반사용자</em>
								</span>
								<router-link to="/findInfo/password" class="button-default is-primary is-large is-rounded">비밀번호 찾기</router-link>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
	</main>
</template>
<script>
import { mapGetters } from 'vuex';
import DropDown from '@/components/common/DropDown';

export default {
	name: 'Join',
	components: {},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
    isFindId(){
      return this.$route.params.division === 'id';
    }
	},
	watch: {},
	data: () => ({}),
	mounted() {},
	methods: {
		clickMoveFANFAN(type) {
			const host = this.isMobile ? 'https://fanfandaero.kr/mobile' : 'https://fanfandaero.kr/portal';
			if(type == 'id'){		
				window.open(`${host}/readUserId.do`);
			} else {
				window.open(`${host}/readUserPwd.do`);
			}			
		},
	},
	destroyed() {},
};
</script>
